export const AndroidService = {
  isNoWindow:
    typeof window === 'undefined' || typeof window.ContestJSI === 'undefined',
  showToast(...args) {
    if (this.isNoWindow) return;
    return window.ContestJSI.toast(...args); //LANDSCAPE, PORTRAIT
  },

  logEvent(id, payload = {}) {
    if (this.isNoWindow) return;
    try {
      const args = [id, JSON.stringify(payload)];
      return window.ContestJSI.logEvent(...args);
    } catch (err) {
      console.error(err);
    }
  },
  redirectTo({ entityType = '', entityId = '' }) {
    if (this.isNoWindow) return;
    const str = JSON.stringify({ entity_type: entityType, entityId: entityId });
    return window.ContestJSI.redirectTo(str);
  },
  openUrlInBrowser(str = '') {
    if (this.isNoWindow) return;

    return window.ContestJSI.openUrlInBrowser(str);
  },
  shareText(subject = '', message = '') {
    if (this.isNoWindow) return;

    return window.ContestJSI.shareText(subject, message);
  },
  getReferralLink() {
    if (this.isNoWindow) return;

    return window.ContestJSI?.getReferralLink();
  }
};
